const facetAccordions = () => {
  var dropdownHeaders = $('.dropdown-heading'); //document.getElementsByClassName("dropdown-heading");

  dropdownHeaders.each(function (e) {
    var $this = $(this);
    $this.on('click', function(){
      $this.next().toggleClass('show');
      $this.toggleClass('active');
    })
  });

  var dropdownItem = $('.dropdown-item label input'); //document.getElementsByClassName("dropdown-item");
  dropdownItem.each(function (e) {
    var $this = $(this);
    if ($this.prop("checked") == true) {
      $this.closest('.dropdown-item').addClass('active');
      $this.closest('.dropdown-content').addClass('show');
      $this.closest('.dropdown-content').prev().addClass('active');
    }
  });

};

function encodeQuery(query) {
  return query.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;");
}

if ($("#content-search").length > 0) {
  const mySearch = new Search.default({
    url: 'https://wood-search01.squiz.co.uk/s/search.json',
    collections: ['wood-meta-rhi'],
    additionalFBparams: {
      profile: '_default',
    },
    results: {
      numRanks: 5,
    },
    onFiltersUpdate: () => {
      facetAccordions();
    },
    pagination: {
      target: '.news__more-wrapper',
      loadMore: true,
      arrows: false,
      arrowsOnEdgePages: false,
      dots: false,
      edgePages: false,
      pages: 1,
    },
    updateUrlOnSearch: true,
    facets: {
      items: [
        {
          name: "Category", // it has to be exactly the same as in funnelback endpoint
          type: "checkbox", // you can choose between 'checkbox' and 'select' field
          options: {
            label: "Job Category", // add it only if you want to name the facet other than in funnelback
            singleChoice: false, // if you want to be able to choose only one option
            facetsRestricted: false // wether you use facetRestricted or not in funnelback
          }
        },
        {
          name: "Location", // it has to be exactly the same as in funnelback endpoint
          type: "checkbox", // you can choose between 'checkbox' and 'select' field
          options: {
            // label: "Location", // add it only if you want to name the facet other than in funnelback
            singleChoice: false, // if you want to be able to choose only one option
            facetsRestricted: false // wether you use facetRestricted or not in funnelback
          }
        },
      ]
    },
    templates: {
      articleTemplate: (fields, highlightFields) => {
        return `
        <article class="job-result">
            <h5 class="job-result__title">${fields.title}</h5>
            <span class="job-result__location">${fields.metaData.itemLocation}</span>
            <div class="job-result__description">
                <p>${fields.metaData.itemOverview}</p>
            </div>
            <a href="${fields.clickTrackingUrl}" class="job-result__more read-more" target="_blank">Read more</a>
        </article>
      `;
      },
      loadMoreTemplate: (fields) => {
        return `
        <button id="search-loadmore" type="button" title="Load more results" class="news__more-btn button">Load More</button>
      `;
      },
      facetCheckbox: ({name, categories, singleChoice, label}, activeFacets, h) => {
        const isActive =  (window.innerWidth > 959) ? `active` :` `;
        const isShow =  (window.innerWidth > 959) ? `show` :` `;
        const header = label ? `<div tabindex="0" class="dropdown-heading ` + isActive  + `">${label}</div>` : `<div tabindex="0" class="dropdown-heading ` + isActive  + `" >${name}</div>`;

        let list = "";

        categories[0].values.forEach(item => {
          list += `<li class="dropdown-item">
                        <label for="${name}-${item.label}" tabindex="0">
                            <input
                            type="checkbox"
                            ${item.checked}
                            value="${item.data}"
                            name="${item.label}"
                            id="${name}-${item.label}"
                            data-fparam="${h.queryStringParamName}"
                            data-singlechoice="${singleChoice}"
                            style="display: none;" />
                        ${item.label}</label>
                    </li>`;
        });

        return `
                <div class="checkbox-facet filters-dropdown" id="${name}">
                    ${header}
                    <ul class="dropdown-content ` + isShow + `">
                        ${list}
                    </ul>
                </div>`;
      },
      activeFacet: (name, param) => {
        return `
              <span param="${param}" name="${name}" tabindex="0">
                ${name}
              </span>
            `;
      },
      noResults: (query, spell) => {
        let spellSuggestion = '';
        let noResults = '';
        if (spell.text) {
          spellSuggestion = `<div>Did you mean: <a href="?${spell.url}">${spell.text}</a>?</div>`;
        }
        if ( query === '!nullsearch' || query === '!showall') {
          noResults = `<div>We're sorry, we couldn't find anything matching your search.</div>${spellSuggestion}`
        } else {
          noResults = `<div>We're sorry, we couldn't find anything matching <strong>${encodeQuery(query)}</strong>.</div>${spellSuggestion}`;
        }
        return noResults;
      },
      informationTemplate: (fields, resultsSummary, h) => {
        let information = ``;
        if (fields.question.query === '!nullsearch' || fields.question.query === '!showall') {
          information= `<h5 style="margin-top:0px; font-size:1.75em; color: #00365F; font-weight: 300;">Showing ${resultsSummary.currStart}-${
              resultsSummary.currEnd
              } of ${resultsSummary.totalMatching}</h5>`;
        } else {
          information = `<h5 style="margin-top:0px; font-size:1.75em; color: #00365F; font-weight: 300;">Search for <b>${encodeQuery(fields.question.query)}</b>. Showing ${resultsSummary.currStart}-${
              resultsSummary.currEnd
              } of ${resultsSummary.totalMatching}</h5>`;
        }
        return information;
      },
    },
  });

  mySearch.init();
}
;

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}  