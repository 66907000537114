(function(){
    var contentSelector = '.js-dynamic-content';
    var formSelector = '.js-dynamic-content form';
    var submitted = false;

    // download dynamic content
    $(contentSelector).each(function(){
        var self = this;
        var url = $(this).attr('data-url');
        if ( typeof url !== 'undefined' ){
            if ( url.length ){
                $.ajax({
                    url: url,
                    cache: false,
                    success: function(data){
                        $(self).html(data);
                        if ( $(self).hasClass('js-parsley-reinit') ){
                            $('form', self).parsley();
                        }
                    }
                });
            }
        }
    });

    // submit dynamic content form
    $('body').on('submit', formSelector, function(e){
        e.preventDefault();
        var self = $(this).parents(contentSelector);
        // form data
        var url = $(this).attr('action');
        var method = $(this).attr('method');

        var submit = $('input[type=submit]', this);
        var submitName = $(submit).attr('name');
        var submitValue = $(submit).attr('value');

        if ( ! submitted ){
            submitted = true;
            $.ajax({
                url: url,
                method: method,
                data: $(this).serialize() + '&' + submitName + '=' + submitValue,
                success: function(data){
                    $(self).html(data);
                },
                complete: function(){
                    submitted = false;
                }
            });
        }
    })
})();