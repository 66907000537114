var Accordion = {
    selectors: {
        'accordionButtons': document.querySelectorAll('.accordion__button'),
        'accordions':  document.querySelectorAll('.accordion__wrapper--js'),
        'accordionItem': document.querySelectorAll('.our-people-item__accordion'),
    },
    init() {
        this.addRequiredReadMore();
        this.buttonClick();
    },
    addRequiredReadMore() {
        [...this.selectors.accordions].map(el => {
            const elHeight = this.checkHeightOfWithChecker(el);
            if (elHeight > 200 ) {
                el.querySelector('.accordion__button').style.display = 'inline-block';
                el.classList.toggle('active');
            }
        })
    },

    checkIfIsActive(target) {
        return target.parentNode.classList.contains('accordion__wrapper--active');
    },
    //@ target = parent of accordion__with-checker
    checkHeightOfWithChecker(target) {
        return target.querySelector('.accordion__with-checker').offsetHeight;
    },
    //@ element - on witch element we will be setting the max-height
    //@ height - what max-height we need to set
    changeHeight(element, height) {
        element.style.maxHeight = height;
    },
    toogleActiveClass(target) {
        target.parentNode.classList.toggle('accordion__wrapper--active');
    },
    buttonClick() {
        [...this.selectors.accordionButtons].map(el => {
            el.addEventListener('click', e => {
                e.preventDefault();
                const target = e.target,
                element =  target.parentNode.querySelector('.accordion__text');
                if(this.checkIfIsActive(target)) {
                    this.changeHeight(element, '235px');
                    target.innerHTML = 'Read more';
                } else {
                    const maxWidthEl = this.checkHeightOfWithChecker(target.parentNode);
                    this.changeHeight(element, `${maxWidthEl + 40}px`);
                    target.innerHTML = 'Read less';
                }
                this.toogleActiveClass(target);
            })
        })
    }
}

Accordion.init();