// var substringMatcher = function (strs) {
//   return function findMatches(q, cb) {
//     var matches, substringRegex;
//
//     // an array that will be populated with substring matches
//     matches = [];
//
//     // regex used to determine if a string contains the substring `q`
//     substringRegex = new RegExp('^' + q, 'i');
//
//     // iterate through the pool of strings and for any string that
//     // contains the substring `q`, add it to the `matches` array
//     $.each(strs, function (i, str) {
//       if (substringRegex.test(str)) {
//         matches.push(str);
//       }
//     });
//
//     cb(matches);
//   };
// };
//
// var states = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California',
//   'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii',
//   'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana',
//   'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota',
//   'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire',
//   'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota',
//   'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island',
//   'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont',
//   'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
// ];
//
// $('.search__wrapper .typeahead').typeahead({
//   hint: true,
//   highlight: true,
//   minLength: 1
// },
//   {
//     name: 'states',
//     source: substringMatcher(states)
//   });



// Mobile search

var mobileSearchButton = document.querySelector('.mobile-search__button');
var mobileSearchWrapper = document.querySelector('.search.mobile-active');
var closeSearchWrapper = document.querySelector('.mobile-search__close');
var topBar = document.querySelector('.top-bar');
var topSearchBox = document.querySelector('.top-search-box');
var searchResultsList = document.querySelector('.search__wrapper .twitter-typeahead .tt-menu');
var searchInput = document.querySelector('.search');
var searchButton = document.querySelector('.search__btn');

mobileSearchButton.addEventListener('click', function () {
  mobileSearchWrapper.classList.toggle('show');
  topBar.classList.toggle('down');
  topSearchBox.classList.toggle('down');
}, false);

closeSearchWrapper.addEventListener('click', function () {
  mobileSearchWrapper.classList.remove('show');
  topBar.classList.remove('down');
  topSearchBox.classList.toggle('down');
}, false);


$(document).on('focus', '.twitter-typeahead', function () {
  $(this).prev().addClass('pink');
});
$(document).on('focusout', '.twitter-typeahead', function () {
  $(this).prev().removeClass('pink');
});


// if (window.innerWidth < 1410) {
//   searchResultsList.style.width = window.innerWidth;
// }
// $(window).on('load', function () {
//   // $('.tt-input').css('background-image', 'none');
//   if (window.innerWidth < 1410) {
//     $('.search.mobile-active .tt-menu').width($(window).width());
//     $('.search.mobile-active .tt-menu').css('left', -30);
//     $('.search.mobile-active .tt-menu').css('padding-left', 30);
//   }
// });
// $(window).on('resize', function () {
//   if (window.innerWidth < 1410) {
//     $('.search.mobile-active .tt-menu').width($(window).width());
//   }
// });