/*global jQuery*/
/**
 * {{name}}
 * Global JS
 *
 * version: {{version}}
 * file:    {{file}}
 * author:  Squiz Australia
 * change log:
 *     {{date}} - First revision
 */

/*
 * Table of Contents
 *
 * - Global
 * - Modules
{{toc}}
 */
"use strict";
/*
--------------------
Global
--------------------
*/
//  Declare JS Enabled.
if (typeof $ !== "undefined") {
  $("body").removeClass("no-js").addClass("js-enabled");

  $(function () {
    AOS.init({
      once: true,
    });
  });
}

/*
--------------------
Modules
--------------------
*/

(function () {
  const loadMoreBtn = document.querySelector(".news__more-btn");
  const newsContainer = document.querySelector(".home-news");
  const maxPages =
    newsContainer && newsContainer.getAttribute("data-page-count");
  loadMoreBtn &&
    loadMoreBtn.addEventListener("click", async function (e) {
      e.preventDefault();
      const loadMoreLink = loadMoreBtn.getAttribute("href");
      const urlParams = new URLSearchParams(loadMoreLink);
      const nextPageNumber = [...urlParams.values()];
      const paramKeys = [...urlParams.keys()];
      const pageNumber = parseInt(nextPageNumber[0]);
      loadMoreBtn.classList.add("loading");
      await fetch(
        `https://www.rhi-group.com/news-media?result_113814_result_page=${pageNumber}`
      )
        .then((res) => {
          return res.text();
        })
        .then((response) => {
          const parser = new DOMParser();
          const result = parser.parseFromString(response, "text/html");
          const newsItems = result.querySelectorAll(".news__item");
          const newsList = document.querySelector(".news__list");
          newsItems.forEach((item) => {
            newsList.appendChild(item);
          });
        })
        .finally(() => {
          loadMoreBtn.classList.remove("loading");
          if (maxPages >= pageNumber + 1) {
            urlParams.set(paramKeys[0], (pageNumber + 1).toString());
            loadMoreBtn.setAttribute("href", urlParams.toString());
          } else {
            loadMoreBtn.remove();
          }
        })
        .catch((err) => {
          const errorMessageNode = document.createElement("p");
          const loadMoreContainer = document.querySelector(
            ".news__more-wrapper"
          );
          errorMessage.innerText =
            "There was an error when loading more items. Please refresh the page and try again.";
          loadMoreContainer.appendChild(errorMessageNode);
          loadMoreBtn.classList.remove("loading");
          throw new Error(err);
        });
    });
})();
