(async function () {
  const keyContacts = document.querySelector(".key-contacts");
  const formURL = keyContacts
    ? keyContacts.getAttribute("data-form-url")
    : null;
    if (keyContacts) {
      await fetch(formURL)
        .then((data) => {
          return data.text();
        })
        .then((res) => {
          const parser = new DOMParser(),
            result = parser.parseFromString(res, "text/html");
          keyContacts.appendChild(result.querySelector("#form_email_226899"));
          const formScript = keyContacts.querySelector("script");
          const fakeScript = document.createElement("script");
          fakeScript.innerHTML = formScript.innerHTML;
          formScript.remove();
          keyContacts.appendChild(fakeScript);
        })
        .catch((err) => {
          throw new Error(err);
        });

      const modalCloseBtn = document.querySelector(
          ".key-contacts__form-close-btn"
        ),
        modalSubmitBtn = keyContacts.querySelector('input[type="submit"]'),
        modalOpenBtns = document.querySelectorAll(".key-contacts__button"),
        modalInputs = keyContacts.querySelectorAll(
          "input:not(input[type='submit']), textarea"
        ),
        modalForm = keyContacts.querySelector("form"),
        emailToInput = keyContacts.querySelector(".hidden.email-input");

      const setLoader = () => {
        document.querySelector("html").classList.remove("modal-open");
        document.body.classList.add("loader");
        modalForm.classList.add("hidden");
      };

      const removeLoader = () => {
        document.body.classList.remove("loader");
        modalForm.classList.remove("hidden");
        document.querySelector("html").classList.add("modal-open");
      };

      const submitForm = (form, formURL) => {
        const formData = new FormData(form);
        const submitBtn = form.querySelector('input[type="submit"]');
        formData.append(
          submitBtn.getAttribute("name"),
          submitBtn.getAttribute("value")
        );
        setLoader();
        fetch(formURL, {
          method: "POST",
          body: formData,
        })
          .then((res) => {
            return res.text();
          })
          .then((response) => {
            const parser = new DOMParser();
            const result = parser.parseFromString(response, "text/html");
            modalForm.classList.add("form-sent");
            modalForm.appendChild(
              result.querySelector(".key-contacts__thank-you")
            );
          })
          .finally(() => {
            removeLoader();
          })
          .catch((err) => {
            console.log(err);
            removeLoader();
            modalForm.classList.add("form-sent");
            const errorPage = document.createElement("div");
            errorPage.innerText = `An error has occured when sending the form. Please reload the page and try again.`;
            errorPage.classList.add("key-contacts__thank-you");
            modalForm.appendChild(errorPage);
            throw new Error(err);
          });
      };

      modalOpenBtns.forEach((btn) => {
        btn.addEventListener("click", (e) => {
          document.querySelector("html").classList.add("modal-open");
          modalForm.classList.add("form-open");
          emailToInput.value = e.target.getAttribute("data-email");
        });
      });

      modalInputs.forEach((input) => {
        input.addEventListener("keydown", (e) => {
          if (e.keyCode === 13) {
            e.preventDefault();
          }
        });
      });

      modalSubmitBtn.addEventListener("click", (e) => {
        e.preventDefault();
        e.stopPropagation();
        const questions = document.querySelectorAll(".sq-form-question");
        const emailRegex = new RegExp(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
        const phoneRegex = new RegExp(
          /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{3})/
        );
        let errorCount = 0;
        questions.forEach((question) => {
          const isRequired = question.querySelector(".sq-form-required-field"),
            questionInput = question.querySelector("input");
          if (isRequired) {
            //validate text inputs
            if (question.classList.contains("sq-form-question-text")) {
              if (questionInput.value !== "") {
                questionInput.classList.remove("input-error");
              } else {
                questionInput.classList.add("input-error");
                errorCount++;
              }
            }

            //validate email inputs
            if (question.classList.contains("sq-form-question-email-address")) {
              if (
                emailRegex.test(questionInput.value) &&
                questionInput.value !== ""
              ) {
                questionInput.classList.remove("input-error");
              } else {
                questionInput.classList.add("input-error");
                errorCount++;
              }
            }

            //validate numeric inputs
            if (question.classList.contains("sq-form-question-numeric")) {
              if (
                questionInput.value !== "" &&
                phoneRegex.test(questionInput.value)
              ) {
                questionInput.classList.remove("input-error");
              } else {
                questionInput.classList.add("input-error");
                errorCount++;
              }
            }
          }
        });
        if (errorCount === 0) {
          submitForm(modalForm, formURL);
        } else {
          alert(
            "The form has been filled in incorrectly. Please review your input and try again!"
          );
        }
        return false;
      });

      modalSubmitBtn.addEventListener("keydown", (e) => {
        if (e.keyCode === 9 && !e.shiftKey) {
          e.preventDefault();
          modalCloseBtn.focus();
        }
      });

      modalForm.addEventListener("submit", (e) => {
        e.stopPropagation();
        e.preventDefault();
        return false;
      });

      modalCloseBtn.addEventListener("click", (e) => {
        e.preventDefault();
        document.querySelector("html").classList.remove("modal-open");
        modalForm.classList.remove("form-open");
        modalInputs.forEach((input) => {
          input.value = "";
          input.classList.remove("input-error");
        });
        const modalThankYou = document.querySelector(
          ".key-contacts__thank-you"
        );
        if (modalThankYou) {
          modalForm.classList.remove("form-sent");
          modalThankYou.remove();
        }
      });

      modalCloseBtn.addEventListener("keydown", (e) => {
        if (e.keyCode === 9 && e.shiftKey) {
          e.preventDefault();
          modalSubmitBtn.focus();
        }
      });
    }
})();