$(function () {
    $('.no-touch #main-nav a').on('focus', function (e) {
        e.preventDefault();
        $(this)
            .parent()
            .addClass('focusin')
            .siblings()
            .removeClass('focusin');
    });
    $(document).on('click focus keyup', function (ev) {
        if (!$(ev.target).is('#main-nav *')) {
            $('#main-nav a')
                .parent()
                .removeClass('focusin');
        }
    });
});


var mobileButton = document.querySelector('.mobile-menu__button');
var closeButton = document.querySelector('.mobile-menu__close');
var headerMenu = document.querySelector('.header-menu');
var globalOverlay = document.querySelector('.global-overlay');

var resetMenu = function () {
    globalOverlay.classList.remove('active');
};

mobileButton.addEventListener('click', e => {
    e.preventDefault();
    headerMenu.classList.add('active');
    globalOverlay.classList.toggle('active');
});

closeButton.addEventListener('click', e => {
    e.preventDefault();
    headerMenu.classList.remove('active');
    resetMenu();
});

[...document.querySelectorAll('.header-menu__link')].map(el => {
    el.addEventListener('click', e => {
        if ( $(e.target).parent().hasClass('has-children') && window.innerWidth < 1024){
            e.preventDefault();
            e.currentTarget.nextElementSibling.classList.toggle('active-item');
            e.currentTarget.classList.toggle('clicked');
        }
    })
});

window.addEventListener('resize', function () {
    if (window.innerWidth > 1300) {
        headerMenu.classList.remove('active');
    }
}, false);

globalOverlay.addEventListener('click', function () {
    resetMenu();
    headerMenu.classList.remove('active');
}, false);